import Bugsnag from '@bugsnag/js'
import React from 'react';
import ErrorPage from 'next/error';

export default class Page extends React.Component {
  static async getInitialProps(ctx) {
    if (process.env.NEXT_PUBLIC_APP_DEBUG !== 'true' && ctx.err) {
      Bugsnag.notify(ctx.err);
    }

    return ErrorPage.getInitialProps(ctx);
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode || 'Error.'} />;
  }
}
