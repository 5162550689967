import Bugsnag from '@bugsnag/js'
import React from 'react';
import Head from 'next/head';
import App from 'next/app';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  Legend,
  PointElement,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Error from './_error';

import '../components/shared/styles/app.scss';
import '../css/style.scss';
import bugsnagStart from '../utils/bugsnag.js'

bugsnagStart()

ChartJS.register(BarElement, CategoryScale, Legend, LinearScale, LineElement, PointElement, Tooltip, ChartDataLabels);

const ErrorBoundary = process.env.NEXT_PUBLIC_APP_DEBUG === 'false' && process.env.NEXT_PHASE !== "phase-production-build"
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : React.createElement('div');

export default class VoizDashboard extends App {
  render() {
    const { Component, pageProps } = this.props;

    if (process.env.NEXT_PUBLIC_APP_DEBUG === 'true') {
      return (
        <>
          <Head>
            <title>Voiz - Adminisztrátori felület</title>
          </Head>
          <Component {...pageProps} />
        </>
      );
    }

    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Head>
          <title>Voiz - Adminisztrátori felület</title>
        </Head>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }
}
